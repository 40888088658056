<template>
  <div>
    <div class="d-flex flex-wrap">
      <AdminHomePageCard
        iconColor="black"
        icon="mdi-text-box-multiple-outline"
        title="Проекты"
        :clickHandler="onProjectClick"
        :number="getAmount.projects"
        :text="projectText"
      />
      <AdminHomePageCard
        iconColor="red"
        icon="mdi-hospital-box-outline"
        title="Профили"
        :clickHandler="onProfilesClick"
        :number="getAmount.profiles"
        :text="profileText"
      />
      <AdminHomePageCard
        iconColor="green"
        icon="mdi-needle"
        title="Заболевания (состояния)"
        :clickHandler="onNosologiaClick"
        :number="getAmount.nosologies"
        :text="nosologiaText"
      />
      <AdminHomePageCard
        iconColor="blue"
        icon="mdi-account"
        title="Пользователи"
        :clickHandler="onUsersClick"
        :number="getAmount.users"
        :text="userText"
      />
    </div>
  </div>
</template>

<script>
import {
  PROFILES,
  USERS,
  NOSOLOGIES,
  PROJECTS,
  COMPONENT_PATHS,
} from '@/store/const/path'
import AdminHomePageCard from '@/components/admin/AdminHomePageCard.vue'
import { mapGetters } from 'vuex'
import { declineByNumber } from '@/lib/declineByNumber'

export default {
  components: {
    AdminHomePageCard,
  },
  computed: {
    ...mapGetters(['getAmount']),
    userText() {
      return declineByNumber('пользователь', this.getAmount.users)
    },
    projectText() {
      return declineByNumber('проект', this.getAmount.projects)
    },
    nosologiaText() {
      const disease = declineByNumber('заболевание', this.getAmount.nosologies)
      const condition = declineByNumber('состояние', this.getAmount.nosologies)
      return `${disease} (${condition})`
    },
    profileText() {
      return declineByNumber('профиль', this.getAmount.profiles)
    },
  },
  methods: {
    onProfilesClick() {
      this.$router.push(COMPONENT_PATHS[PROFILES].path)
    },
    onUsersClick() {
      this.$router.push(COMPONENT_PATHS[USERS].path)
    },
    onProjectClick() {
      this.$router.push(COMPONENT_PATHS[PROJECTS].path)
    },
    onNosologiaClick() {
      this.$router.push(COMPONENT_PATHS[NOSOLOGIES].path)
    },
  },
}
</script>

<style scoped>
.v-card {
  cursor: pointer;
}
.service {
  border: 1px dashed black;
  padding: 8px;
  border-radius: 6px;
  margin-top: 24px;
}
</style>
