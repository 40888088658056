<template>
  <v-card class="ma-3" width="400" @click="clickHandler">
    <v-card-title
      ><v-icon :color="iconColor" class="mr-2"> {{ icon }} </v-icon> {{ title }}
    </v-card-title>
    <v-card-text class="pl-11">
      <span class="display-1"> {{ number }} </span>
      <span> {{ text }} </span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['iconColor', 'icon', 'title', 'clickHandler', 'number', 'text'],
}
</script>
